<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card class="min-vh-100">
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
        <b-row>
          <b-col>
            <div class="mb-4">
              <h6>Type ads</h6>
              <span class="text-capitalize">{{models.type}}</span>
            </div>
            <div class="my-4" v-if="type_ads === 'embed'">
              <div v-if="vars.videoSrc" class="d-flex justify-content-center">
                <iframe ref="frame" width="100%" height="440" :src="vars.videoSrc">
                </iframe>
              </div>
            </div>
            <div class="my-4" v-else>
              <a :href="models.path" target="_blank">
                <b-img :src="getUrlOfFile(models.path)" fluid class="d-block m-auto" style="max-height: 300px"/>
              </a>
            </div>
          </b-col>
        </b-row>
				<b-row>
					<b-col md="5">
            <div class="mb-4">
              <h6>Name</h6>
              <span class="text-capitalize">{{models.name}}</span>
            </div>
            <div class="mb-4">
              <h6>Start Date</h6>
              <span class="text-capitalize">{{moment(models.start_date).format('DD - MM - YYYY')}}</span>
            </div>
						<div class="mb-4">
              <h6>Link</h6>
              <a :href="models.link" target="_blank">{{models.link}}</a>
            </div>
					</b-col>
					<b-col md="5">
            <div class="mb-4">
              <h6>End Date</h6>
              <span class="text-capitalize">{{moment(models.end_date).format('DD - MM - YYYY')}}</span>
            </div>
            <div class="mb-4">
              <h6>Status</h6>
              <span :class="'badge badge-' + getClassStatus(models.status)" class="text-capitalize">
                {{models.status}}
              </span>
            </div>
					</b-col>
				</b-row>
        <template #footer>
					<b-button block type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
	</form>
</template>
<script>
	import moment from 'moment'
	import api from '@/utils/api.js'
	import getYoutubeId from 'get-youtube-id'
  import { getClassStatus } from '@/utils/helpers';


  import {
		getUrlOfFile
	} from '@/utils/helpers.js';

	export default {
		name: 'DetailAdvertisement',
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					name: "",
          type: "image",
          link: "",
          path: "",
          start_date: "",
          end_date: "",
          status: "publish"
				},
        vars: {
					videoSrc: null,
					videoUrlActive: false,
					onLoadData: false
				},
				moment
			}
		},
		created() {
			let _ = this
			_.getById();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.vars.onLoadData = true
					_.$axios.get(`${api.advertisement}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.name = data.name,
							_.models.type = data.type,
							_.models.link = data.link,
							_.models.path = data.path,
							_.models.start_date = moment(data.start_date).format('YYYY-MM-DD'),
							_.models.end_date = moment(data.end_date).format('YYYY-MM-DD'),
							_.models.status = data.status

							if(data.type === 'embed') {
								this.getSrcVideo()
							}

							this.$nextTick(() => {
								_.vars.onLoadData = false
							});
						}).catch(() => {
							_.vars.onLoadData = false
							_.$router.push({
								name: 'page404'
							})
						});
				}
			},

      getClassStatus(status) {
				return getClassStatus(status);
			},

      getUrlOfFile(file) {
				return getUrlOfFile(file, true);
			},

      getSrcVideo() {
				const fileUrl = this.models.path
				if(fileUrl.toLowerCase().includes('youtube.com')) {
					this.vars.videoSrc = 'https://www.youtube.com/embed/' + getYoutubeId(this.models.path)
				} else {
					this.vars.videoSrc = this.models.path
				}
			}
		},
    computed: {
      type_ads() {
        return this.models.type
      }
    },
    watch: {
      type_ads() {
				if(!this.vars.onLoadData) {
					this.models.path = ""
					this.vars.videoSrc = ""
					this.vars.videoUrlActive = false
				}
      }
    }
	}
</script>